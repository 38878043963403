* {
  box-sizing: border-box;
}

body,
h1,
h2 {
  margin: 0;
  padding: 0;
}

body {
  font-family: Open Sans, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
  -webkit-font-smoothing: antialiased;
}

h2 {
  color: #21243d;
  font-family: Hind, sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
}


/* Misc from reset-min.css */
.ais-Breadcrumb-list,
.ais-CurrentRefinements-list,
.ais-HierarchicalMenu-list,
.ais-Hits-list,
.ais-InfiniteHits-list,
.ais-InfiniteResults-list,
.ais-Menu-list,
.ais-NumericMenu-list,
.ais-Pagination-list,
.ais-RatingMenu-list,
.ais-RefinementList-list,
.ais-Results-list,
.ais-ToggleRefinement-list {
 margin:0;
 padding:0;
 list-style:none
}
.ais-CurrentRefinements-delete,
.ais-CurrentRefinements-reset,
.ais-GeoSearch-redo,
.ais-GeoSearch-reset,
.ais-HierarchicalMenu-showMore,
.ais-InfiniteHits-loadMore,
.ais-InfiniteHits-loadPrevious,
.ais-InfiniteResults-loadMore,
.ais-Menu-showMore,
.ais-RangeInput-submit,
.ais-RefinementList-showMore,
.ais-SearchBox-reset,
.ais-SearchBox-submit,
.ais-VoiceSearch-button {
 padding:0;
 overflow:visible;
 font:inherit;
 line-height:normal;
 color:inherit;
 background:none;
 border:0;
 cursor:pointer;
 -webkit-user-select:none;
 -moz-user-select:none;
 -ms-user-select:none;
 user-select:none
}
.ais-ClearRefinements-button::-moz-focus-inner,
.ais-CurrentRefinements-delete::-moz-focus-inner,
.ais-CurrentRefinements-reset::-moz-focus-inner,
.ais-GeoSearch-redo::-moz-focus-inner,
.ais-GeoSearch-reset::-moz-focus-inner,
.ais-HierarchicalMenu-showMore::-moz-focus-inner,
.ais-InfiniteHits-loadMore::-moz-focus-inner,
.ais-InfiniteHits-loadPrevious::-moz-focus-inner,
.ais-InfiniteResults-loadMore::-moz-focus-inner,
.ais-Menu-showMore::-moz-focus-inner,
.ais-RangeInput-submit::-moz-focus-inner,
.ais-RefinementList-showMore::-moz-focus-inner,
.ais-SearchBox-reset::-moz-focus-inner,
.ais-SearchBox-submit::-moz-focus-inner,
.ais-VoiceSearch-button::-moz-focus-inner {
 padding:0;
 border:0
}
.ais-ClearRefinements-button[disabled],
.ais-CurrentRefinements-delete[disabled],
.ais-CurrentRefinements-reset[disabled],
.ais-GeoSearch-redo[disabled],
.ais-GeoSearch-reset[disabled],
.ais-HierarchicalMenu-showMore[disabled],
.ais-InfiniteHits-loadMore[disabled],
.ais-InfiniteHits-loadPrevious[disabled],
.ais-InfiniteResults-loadMore[disabled],
.ais-Menu-showMore[disabled],
.ais-RangeInput-submit[disabled],
.ais-RefinementList-showMore[disabled],
.ais-SearchBox-reset[disabled],
.ais-SearchBox-submit[disabled],
.ais-VoiceSearch-button[disabled] {
 cursor:default
}
.ais-Breadcrumb-item,
.ais-Breadcrumb-list,
.ais-Pagination-list,
.ais-PoweredBy,
.ais-RangeInput-form,
.ais-RatingMenu-link {
 display:-webkit-box;
 display:-ms-flexbox;
 display:flex;
 -webkit-box-align:center;
 -ms-flex-align:center;
 align-items:center
}
.ais-GeoSearch,
.ais-GeoSearch-map {
 height:100%
}
.ais-HierarchicalMenu-list .ais-HierarchicalMenu-list {
 margin-left:1em
}
.ais-PoweredBy-logo {
 display:block;
 height:1.2em;
 width:auto
}
.ais-RatingMenu-starIcon {
 display:block;
 width:20px;
 height:20px
}
.ais-SearchBox-input::-ms-clear,
.ais-SearchBox-input::-ms-reveal {
 display:none;
 width:0;
 height:0
}
.ais-SearchBox-input::-webkit-search-cancel-button,
.ais-SearchBox-input::-webkit-search-decoration,
.ais-SearchBox-input::-webkit-search-results-button,
.ais-SearchBox-input::-webkit-search-results-decoration {
 display:none
}
.ais-RangeSlider .rheostat {
 overflow:visible;
 margin-top:40px;
 margin-bottom:40px
}
.ais-RangeSlider .rheostat-background {
 height:6px;
 top:0;
 width:100%
}
.ais-RangeSlider .rheostat-handle {
 margin-left:-12px;
 top:-7px
}
.ais-RangeSlider .rheostat-background {
 position:relative;
 background-color:#fff;
 border:1px solid #aaa
}
.ais-RangeSlider .rheostat-progress {
 position:absolute;
 top:1px;
 height:4px;
 background-color:#333
}
.rheostat-handle {
 position:relative;
 z-index:1;
 width:20px;
 height:20px;
 background-color:#fff;
 border:1px solid #333;
 border-radius:50%;
 cursor:-webkit-grab;
 cursor:grab
}
.rheostat-marker {
 margin-left:-1px;
 position:absolute;
 width:1px;
 height:5px;
 background-color:#aaa
}
.rheostat-marker--large {
 height:9px
}
.rheostat-value {
 padding-top:15px
}
.rheostat-tooltip,
.rheostat-value {
 margin-left:50%;
 position:absolute;
 text-align:center;
 -webkit-transform:translateX(-50%);
 transform:translateX(-50%)
}
.rheostat-tooltip {
 top:-22px
}

/* Header */

.header {
  align-items: center;
  background: linear-gradient(to bottom, #fbc300, #c98a00);
  background-image: url('./assets/cover.png');
  background-image: url('./assets/cover.png'),
    linear-gradient(to bottom, #fbc300, #c98a00);
  background-position: center;
  background-size: cover;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 368px;
  padding: 0.5rem 1rem;
  text-align: center;
}

.header-logo {
  margin: 0;
}

.header-logo svg {
  height: 100px;
  width: 500px;
}

.header-title {
  font-size: 38px;
  font-weight: 300;
}

/* Containers */

.container {
  display: flex;
  margin: 0 auto;
  max-width: 1300px;
  padding: 2rem 1rem;
}

.container-filters {
  flex: 1;
  margin-right: 60px;
  max-width: 260px;
  min-width: 200px;
}

.container-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  min-height: 80px;
}

.container-results {
  flex: 3;
}

.container-options {
  border-bottom: 1px solid #ebecf3;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
  padding: 30px 0;
}

.container-options .container-option:not(:first-child) {
  margin-left: 48px;
}

.container-options select {
  min-width: 100px;
}

.container-footer {
  margin: 4rem 0;
}

/* Styles the SFFV highlightings */

em {
  font-style: normal;
}

em,
mark {
  background: rgba(226, 164, 0, 0.4);
}

/* Clear refinements container */

.clear-filters {
  align-items: center;
  display: flex;
}

.clear-filters svg {
  margin-right: 8px;
}

/* Panel */

.container-body .ais-Panel {
  border-top: 1px solid #ebecf3;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.ais-Panel-header {
  font-family: Hind, sans-serif;
}

/* Search box */

.header .ais-SearchBox {
  height: 64px;
  width: 740px;
}

.header .ais-SearchBox .ais-SearchBox-input {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 48px 0 rgba(0, 0, 0, 0.2);
  font-family: Hind, sans-serif;
  height: 64px;
  /*
    The "Hind" font family is vertically off-balance.
    Adding 4px of padding top makes it more vertically aligned.
  */
  padding: 4px 48px 0 64px;
}

.header .ais-SearchBox-submit {
  padding: 0 1rem 0 2rem;
  width: 64px;
}

.header .ais-SearchBox .ais-SearchBox-input::placeholder {
  color: rgba(33, 36, 61, 0.24);
  opacity: 1; /* Firefox */
}

.ais-SearchBox-input:-ms-input-placeholder {
  color: rgba(33, 36, 61, 0.24);
}

.ais-SearchBox-input::-ms-input-placeholder {
  color: rgba(33, 36, 61, 0.24);
}

.ais-SearchBox-submit {
  color: #e2a400;
}

.ais-RefinementList .ais-SearchBox-input {
  font-family: Hind, sans-serif;
  /*
    The "Hind" font family is vertically off-balance.
    Adding some padding top makes it more vertically aligned.
  */
  padding-top: 2px;
}

/* Hits */

.hit {
  color: #21243d;
  font-size: 14px;
  line-height: 18px;
}

.hit h1 {
  font-size: 14px;
}

.hit-category {
  color: #21243d;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 8px;
  opacity: 0.7;
  text-transform: uppercase;
}

.hit-description {
  margin-top: 2px;
}

.hit-author {
    margin-top: 2px;
}

.hit-info-container {
  overflow-wrap: break-word;
  word-break: break-word;
}

.hit-image-container {
  align-items: center;
  display: flex;
  height: 174px;
  justify-content: center;
  margin: auto;
  width: 174px;
}

.hit-image {
  height: auto;
  max-height: 100%;
  max-width: 100%;
}

.hit-em {
  color: #e2a400;
  font-size: 11px;
  font-weight: 600;
}

.hit-rating {
  border: 1px solid rgba(226, 164, 0, 0.5);
  border-radius: 4px;
  margin-left: 4px;
  padding: 0 4px;
}

.hit-link {
    text-decoration: none;
    color: #0F1111;
}

.hit-link:hover {
   color: #BB4D18;
}

.hit-map-icon {
    maxHeight: 24px;
    maxWidth: 24px;
}

.hits-empty-state {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 300px;
}

.hits-empty-state-title {
  font-family: Hind;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0;
  text-align: center;
}

.hits-empty-state-description {
  color: rgba(35, 37, 51, 0.6);
  font-size: 0.875rem;
  text-align: center;
}

.hits-empty-state .ais-ClearRefinements {
  margin-top: 1rem;
}

.hits-empty-state .ais-ClearRefinements-button--disabled {
  display: none;
}

.hits-empty-state .ais-ClearRefinements-button {
  background: rgba(10, 8, 41, 0.04);
  border-radius: 3px;
  color: #21243d;
  min-height: 48px;
  padding: 16px 24px;
}

/* ToggleRefinement */

.ais-ToggleRefinement-label {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.ais-ToggleRefinement-checkbox {
  font: inherit;
  margin-left: 1rem;
  margin-right: 0;
  position: relative;
}

.ais-ToggleRefinement-checkbox:checked::before {
  color: #e2a400;
}

.ais-ToggleRefinement-checkbox::before {
  align-items: center;
  color: rgba(33, 36, 61, 0.32);
  content: 'No';
  display: flex;
  font-size: 0.8rem;
  height: 16px;
  position: absolute;
  right: 38px;
}

.ais-ToggleRefinement-checkbox:checked::before {
  content: 'Yes';
}

.ais-ToggleRefinement-count {
  display: none;
}

/* RatingMenu */

.ais-RatingMenu-item:not(.ais-RatingMenu-item--selected) {
  opacity: 0.5;
}

.ais-RatingMenu-starIcon {
  margin-right: 0.5rem;
}

/* Hide all mobile-specific design on desktop */

@media (min-width: 900px) {
  [data-layout='mobile'] {
    display: none;
  }
}
